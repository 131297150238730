import React, { useMemo } from "react";
import { useState, useEffect } from "react";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import { Navigate } from "react-router";
import mainApi from "./utils/MainApi";
import { Notyf } from "notyf";
import Preloader from "./components/Preloader/Preloader";
import Popup from "./components/Popup/Popup";
import imageLoading from "./assets/img/loading.gif";
import {
  MESSENGERS,
  STATUSES_ORDER,
  STATUS_CONNECT_TG,
  TEXTS_STATUSES,
} from "./utils/constants";
import ConnectingWA from "./components/ConnectForms/ConnectingWA/ConnectingWA";
import ConnectingTG from "./components/ConnectForms/ConnectingTG/ConnectingTG";
import SelectChats from "./components/SelectChats/SelectChats";
import "notyf/notyf.min.css";
import AdminPanel from "./components/Admin/Admin";
import WebSocketComponent from "./components/WebSocket/WebSocket";
import {
  formatDateToShortString,
  getEndDay,
  replaceValuesForMessages,
  setCookie,
} from "./utils/utils";
import MessageList from "./components/MessageList/MessageList";

const initialCurrentUser = {
  email: "",
  name: "",
  favourite_chats_tg: [],
  favourite_chats_wa: [],
  profile_tg: null,
  profile_wa: null,
  _id: "",
};

const App = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();
  const url = location.pathname;
  const notyf = useMemo(() => new Notyf({ duration: 3000 }), []);

  // авторизован клиент или нет
  const [isUserAuthed, setIsUserAuthed] = useState(false);
  // открыт бургер меню или нет
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false);
  // загрузки идет или нет
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  // данные текущего пользователя
  const [currentUser, setСurrentUser] = useState(initialCurrentUser);
  // профили для авторизации в системе
  const [profileTg, setProfileTg] = useState(null);
  const [profileWa, setProfileWa] = useState(null);
  // текст уведомления
  const [notifyError, setNotifyError] = useState("");
  const [notifyInfo, setNotifyInfo] = useState("");
  // процесс авторизации в whatsapp/tg
  const [isConnectingWa, setIsConnectingWa] = useState(false);
  const [qrWa, setQrWa] = useState(null);
  const [connectingTgStatus, setConnectingTG] = useState(
    STATUS_CONNECT_TG.NONE
  );
  // настройка чатов
  const [allChatsWA, setAllChatsWA] = useState([]);
  const [allChatsTG, setAllChatsTG] = useState([]);
  const [isSelectChatsWA, setIsSelectChatsWA] = useState(false);
  const [isSelectChatsTG, setIsSelectChatsTG] = useState(false);

  // все отчеты
  const [allReports, setAllReports] = useState([]);
  const [currentReport, setCurrentReport] = useState(null);
  const [msgsCurrentReport, setMsgsCurrentReport] = useState([]);
  const [isOpenPopupMessages, setIsOpenPopupMessages] = useState(false);

  // фильтры сообщений
  const [senderFilter, setSenderFilter] = useState("");
  const [emotionFilter, setEmotionFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  // ошибки приходят сюда
  const handlerErrors = (err) => {
    console.log("handlerErrors", err);
    let text;
    switch (err.message) {
      case "Failed to fetch":
        text = "Проблемы с интернетом или сервером";
        break;
      case "Validation failed":
        text = "Email указан некорректно";
        break;
      default:
        text = err.message;
    }
    setNotifyError(text);
    console.log(err);
  };
  // функция удаления данных пользователя с приложения
  const removeUserData = (notify = true) => {
    setIsUserAuthed(false);
    localStorage.clear();
    setСurrentUser(initialCurrentUser);
    clearProfiles();
  };
  // функция удаления данных профилей
  const clearProfiles = () => {
    setProfileTg(null);
    setProfileWa(null);
    setConnectingTG(STATUS_CONNECT_TG.NONE);
    setAllChatsTG([]);
    setAllChatsWA([]);
    setCookie("jwt", "", {
      "max-age": -1,
    });
  };

  // проверка данных пользователя на сервере
  const checkUser = async () => {
    try {
      setIsLoading(true);
      const data = await mainApi.onCheckUser();
      if (!data.error) {
        setIsUserAuthed(true);
        setСurrentUser(data);
        navigate(url);
      } else {
        removeUserData(false);
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  // загрузка профилей
  const loadProfiles = async () => {
    try {
      setIsLoading(true);

      const data = await mainApi.loadProfiles();
      if (!data.error) {
        const tg = data?.find((p) => p.profile_id === currentUser?.profile_tg);
        const wa = data?.find((p) => p.profile_id === currentUser?.profile_wa);
        setProfileTg(tg ? { ...tg, platform: MESSENGERS.TG } : null);
        setProfileWa(wa ? { ...wa, platform: MESSENGERS.WA } : null);
        return { tg, wa };
      } else {
        setNotifyError("Не удалось получить профили");
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  // авторизация в WA  получение QR кода
  const getQrWa = async () => {
    try {
      setIsLoading(true);
      const profiles = await loadProfiles();
      if (profiles.wa.authorized) {
        setIsConnectingWa(false);
        return;
      }
      const data = await mainApi.getQrWa();
      if (!data.error) {
        setQrWa(data.qrCode);
      } else {
        setNotifyError(
          "Не удалось Загрузить QR код для авторизации в Whatsapp"
        );
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  // авторизация в тг
  const authTG = async (status, content) => {
    try {
      setIsLoading(true);
      let data;
      if (status === STATUS_CONNECT_TG.PHONE) {
        data = await mainApi.sendPhoneTG(currentUser.profile_tg, content);
      }
      if (status === STATUS_CONNECT_TG.CODE) {
        data = await mainApi.sendCodeTG(currentUser.profile_tg, content);
      }
      if (status === STATUS_CONNECT_TG.PASSWORD) {
        data = await mainApi.sendPasswordTG(currentUser.profile_tg, content);
      }
      if (!data?.error) {
        return data;
      } else {
        setNotifyError("Не удалось отправить код ");
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  // функция создания пользователя - регистрация
  const handleCreateUser = async ({ email, password, name }) => {
    setIsLoading(true);
    mainApi
      .onRegister(email, password, name)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        checkUser()
          .then(() => {
            navigate("/movies");
          })
          .catch(handlerErrors);
      })
      .catch(handlerErrors)
      .finally(() => setIsLoading(false));
  };

  // функция авторизации пользователя - вход
  const handleLogin = async (email, password) => {
    setIsLoading(true);
    mainApi
      .onLogin({ email, password })
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        checkUser()
          .then(() => {
            navigate("/");
            setNotifyInfo(`Авторизация успешна`);
          })
          .catch(handlerErrors);
      })
      .catch(handlerErrors)
      .finally(() => setIsLoading(false));
  };

  // функция выхода пользователя и удаления куков,а также всех данных
  const handleSignOut = async () => {
    setIsLoading(true);
    mainApi
      .onLogout()
      .then(() => {
        removeUserData();
        setNotifyInfo(`Вы вышли`);
        navigate("/");
      })
      .catch(handlerErrors)
      .finally(() => setIsLoading(false));
  };

  // функция изменения данных пользователя
  const handleEditUser = async ({ name, email }) => {
    setIsLoading(true);
    mainApi
      .onEditProfile({ name, email })
      .then((response) => {
        if (response.error) {
          removeUserData();
          throw new Error(response.error);
        }
        setNotifyError(`Новые имя и емейл сохранены!`);
        setСurrentUser(response);
      })
      .catch(handlerErrors)
      .finally(() => setIsLoading(false));
  };

  // авторизация в мессенжере
  const handleConnect = async (messenger) => {
    if (messenger === MESSENGERS.WA) {
      setIsConnectingWa(true);
      await getQrWa();
    }
    if (messenger === MESSENGERS.TG) {
      setConnectingTG(STATUS_CONNECT_TG.PHONE);
    }
  };

  // обработка отключения от профиля
  const handleDisConnect = async (messenger) => {
    try {
      setIsLoading(true);
      if (messenger === MESSENGERS.WA) {
        await mainApi.logoutMessenger(currentUser?.profile_wa);
      }
      if (messenger === MESSENGERS.TG) {
        await mainApi.logoutMessenger(currentUser?.profile_tg);
      }
      setTimeout(() => clearProfiles(), 3000);
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadChats = async () => {
    try {
      setIsLoadingChats(true);
      if (profileWa?.authorized) {
        const resWA = await mainApi.getChats(currentUser?.profile_wa);
        setAllChatsWA(resWA.dialogs);
      }
      if (profileTg?.authorized) {
        const resTG = await mainApi.getChats(currentUser?.profile_tg);
        setAllChatsTG(resTG.dialogs);
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoadingChats(false);
    }
  };
  const getTopicsTG = async (profile_id, chat_id) => {
    try {
      setIsLoading(true);
      if (profile_id && chat_id) {
        const response = await mainApi.getTopicsTG(profile_id, chat_id);
        if (response) return response;
      }
      return { topics: [] };
    } catch (error) {
      handlerErrors(error);
      return { topics: [] };
    } finally {
      setIsLoading(false);
    }
  };
  const getReports = async () => {
    try {
      const allReports = await mainApi.getReports();
      setAllReports(allReports);
    } catch (error) {
      handlerErrors(error);
    }
  };
  const getReport = async (id) => {
    try {
      setIsLoading(true);
      const report = await mainApi.getReport(id);
      if (!report.message) {
        setCurrentReport(report);
      } else {
        setNotifyError("Не удалось загрузить отчет");
        setCurrentReport({
          status_order: TEXTS_STATUSES.error,
          _id: id,
          ...report,
        });
      }
    } catch (error) {
      setCurrentReport({ status_order: TEXTS_STATUSES.error, _id: id });
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };
  const delByReport = async (id) => {
    try {
      setIsLoading(true);
      const report = await mainApi.delByReport(id);
      if (report) {
        setAllReports((oldReports) =>
          oldReports.filter((r) => r.id !== report._id)
        );
        setCurrentReport(null);
        setNotifyInfo(`отчет <b>${report.name}</b> удален`);
      } else {
        if (report.error) {
          setNotifyError(report.error);
        } else {
          setNotifyError("Не удалось удалить отчет");
        }
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChats = async (messenger) => {
    try {
      if (messenger === MESSENGERS.WA) {
        setIsSelectChatsWA(true);
      }
      if (messenger === MESSENGERS.TG) {
        setIsSelectChatsTG(true);
      }
    } catch (error) {
      handlerErrors(error);
    }
  };
  const handleLoadChats = async (profile_id) => {
    try {
      setIsLoading(true);
      let res;
      if (profile_id === currentUser?.profile_wa) {
        res = await mainApi.getChats(currentUser?.profile_wa);
        if (res.status === "done") {
          setAllChatsWA(res.dialogs);
        }
      }
      if (profile_id === currentUser?.profile_tg) {
        res = await mainApi.getChats(currentUser?.profile_tg);
        if (res.status === "done") {
          setAllChatsTG(res.dialogs);
        }
      }
      if (!res || !res.dialogs) {
        setNotifyError("Ошибка загрузки чатов. Попробуйте чуть позже");
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFormSumbitFilter = async ({
    chat,
    endDate,
    startDate,
    topic_id,
  }) => {
    try {
      setIsLoading(true);
      const res = await mainApi.createReport({
        profile_id: chat.profile_id,
        chat_id: chat.value,
        start_date: startDate.getTime(),
        end_date: getEndDay(endDate).getTime(),
        name: chat.label,
        topic_id: topic_id.value,
        topic_name: topic_id.value ? topic_id.label : undefined,
      });
      if (res?.message) {
        setNotifyInfo(res.message);
      }
      if (res.order) {
        setAllReports([
          {
            name: res.order.name,
            status_order: res.order.status_order,
            start_date: formatDateToShortString(res.order.start_date),
            end_date: formatDateToShortString(res.order.end_date),
            id: res.order._id,
          },
          ...allReports,
        ]);
        setCurrentReport(res.order);
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateStatusOrder = (msg, setAllReports) => {
    if (msg?.type === "status_order") {
      setAllReports((reports) =>
        reports.map((r) => {
          if (r.id === msg.content.order_id) {
            if (msg.content.status_order === STATUSES_ORDER.COMPLETED) {
              setNotifyInfo(`Отчет <b>${r.name}</b> успешно сформирован`);
              setMsgsCurrentReport([]);
              getReport(msg.content.order_id);
            } else {
              if (msg.content.status_order === STATUSES_ORDER.ERROR) {
                setNotifyError(
                  `Произошла ошибка при создании отчета <b>${r.name}</b>`
                );
              }
              setCurrentReport((current) => ({
                ...current,
                status_order: msg.content.status_order,
                progress: msg.content.progress,
              }));
            }
            return {
              ...r,
              status_order: msg.content.status_order,
              progress: msg.content.progress,
            };
          } else {
            return r;
          }
        })
      );
    }
  };
  const handleLoadMessagesCurrentReport = async (id) => {
    if (msgsCurrentReport.length) {
      setIsOpenPopupMessages(true);
      return;
    }
    try {
      setIsLoading(true);
      const messages = await mainApi.getMessages(id);
      if (messages) {
        setMsgsCurrentReport(replaceValuesForMessages(messages));
        setIsOpenPopupMessages(true);
      } else {
        if (messages.error) {
          setNotifyError(messages.error);
        } else {
          setNotifyError("Не удалось загрузить сообщения");
        }
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLogs = async () => {
    mainApi.getLogs().catch(handlerErrors);
  };

  const handleClickInLabelForFilter = (filter, value) => {
    handleLoadMessagesCurrentReport(currentReport?._id);
    switch (filter) {
      case "emotion":
        setEmotionFilter(value);
        break;
      case "type":
        setTypeFilter(value);
        break;
      case "sender":
        setSenderFilter(value);
        break;
      default:
        break;
    }
  };
  //при первой загрузке, если клиент не авторизован проверяем его данные на сервере
  useEffect(() => {
    if (!isUserAuthed) {
      try {
        setIsLoading(true);
        checkUser();
      } catch (error) {
        handlerErrors(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  // загружаем данные профилей
  useEffect(() => {
    if (isUserAuthed && !profileTg && !profileWa) {
      try {
        setIsLoading(true);
        loadProfiles();
      } catch (error) {
        handlerErrors(error);
      } finally {
        setIsLoading(false);
      }
    }
    if (
      (profileTg || profileWa) &&
      allChatsWA &&
      allChatsTG &&
      [...allChatsWA, ...allChatsTG].length === 0
    ) {
      loadChats();
    }
    getReports();
  }, [isUserAuthed, profileTg, profileWa]);

  // если мы добавили текст в уведомления, то открываем модалку
  useEffect(() => {
    if (notifyError) notyf.error?.(notifyError);
    setNotifyError("");
  }, [notifyError, notyf]);

  useEffect(() => {
    if (notifyInfo) notyf.success?.(notifyInfo);
    setNotifyInfo("");
  }, [notifyInfo, notyf]);

  // возвращаем на страницу, куда было обращение
  useEffect(() => {
    if (isUserAuthed) {
      navigate(url);
    }
  }, [isUserAuthed]);

  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/admin"
          element={
            <ProtectedRoute
              isUserAuthed={currentUser.is_admin}
              element={
                <AdminPanel
                  handlerErrors={handlerErrors}
                  setIsLoading={setIsLoading}
                  setNotifyInfo={setNotifyInfo}
                  setNotifyError={setNotifyError}
                  handleSignOut={handleSignOut}
                  getLogs={getLogs}
                />
              }
            />
          }
        />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route
          path="/"
          element={
            <ProtectedRoute
              isUserAuthed={isUserAuthed}
              element={
                <Dashboard
                  currentUser={currentUser}
                  onLogout={handleSignOut}
                  profileTg={profileTg}
                  profileWa={profileWa}
                  handleConnect={handleConnect}
                  handleDisConnect={handleDisConnect}
                  handleSelectChats={handleSelectChats}
                  allChatsWA={allChatsWA ?? []}
                  allChatsTG={allChatsTG ?? []}
                  loadChats={loadChats}
                  handleFormSumbitFilter={handleFormSumbitFilter}
                  allReports={allReports}
                  getReport={getReport}
                  currentReport={currentReport}
                  onCloseReport={() => {
                    setCurrentReport(null);
                    setMsgsCurrentReport([]);
                  }}
                  onLoadMessages={handleLoadMessagesCurrentReport}
                  delByReport={delByReport}
                  handleClickInLabelForFilter={handleClickInLabelForFilter}
                  isLoadingChats={isLoadingChats}
                  getTopicsTG={getTopicsTG}
                />
              }
            />
          }
        />
      </Routes>
      <ConnectingWA
        isOpen={isConnectingWa}
        setIsConnectingWa={setIsConnectingWa}
        qrWa={qrWa ?? imageLoading}
        clearProfiles={clearProfiles}
      />
      <ConnectingTG
        setConnectingTG={setConnectingTG}
        connectingTgStatus={connectingTgStatus}
        clearProfiles={clearProfiles}
        sendPhoneTG={(content) => authTG(STATUS_CONNECT_TG.PHONE, content)}
        sendCodeTG={(content) => authTG(STATUS_CONNECT_TG.CODE, content)}
        sendPasswordTG={(content) =>
          authTG(STATUS_CONNECT_TG.PASSWORD, content)
        }
        setNotifyError={setNotifyError}
        setIsLoading={setIsLoading}
      />
      <SelectChats
        isOpen={isSelectChatsTG || isSelectChatsWA}
        setPopupClose={() => {
          setIsSelectChatsTG(false);
          setIsSelectChatsWA(false);
        }}
        allChats={isSelectChatsTG ? allChatsTG : allChatsWA}
        profile_id={
          isSelectChatsTG ? currentUser.profile_tg : currentUser.profile_wa
        }
        updateChats={handleLoadChats}
      />
      <MessageList
        isOpen={isOpenPopupMessages}
        setPopupClose={(value) => {
          setIsOpenPopupMessages(value);
          !value && setMsgsCurrentReport([]);
        }}
        messages={msgsCurrentReport ?? []}
        senderFilter={senderFilter}
        emotionFilter={emotionFilter}
        typeFilter={typeFilter}
        setSenderFilter={setSenderFilter}
        setEmotionFilter={setEmotionFilter}
        setTypeFilter={setTypeFilter}
        currentReport={currentReport}
      />
      {isLoading && <Preloader />}
      {currentUser._id && (
        <WebSocketComponent
          id={currentUser._id}
          handleUpdateStatusOrder={handleUpdateStatusOrder}
          setAllReports={setAllReports}
        />
      )}
    </AuthProvider>
  );
};

const ProtectedRoute = ({ element, isUserAuthed }) => {
  return isUserAuthed ? element : <Navigate to="/login" />;
};

export default App;
