import React, { useState, useRef, useMemo, useEffect } from "react";
import styles from "./Filters.module.css";
import inputStyles from "../Input/Input.module.css";
import Button from "../Button/Button";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import "../../assets/css/flatpicker.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Select from "react-select";
import {
  selectTheming,
  DropdownIndicator,
  ClearIndicator,
} from "../../utils/CSelect";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { getStorageNameSelectedChats } from "../../utils/constants";

const Filters = ({
  onSubmit,
  profileTg,
  profileWa,
  allChatsWA,
  allChatsTG,
  loadChats,
  currentUser,
  isLoadingChats,
  getTopicsTG,
}) => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [allTopicsTG, setAllTopicsTG] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [favouritesChatsWA, setFavouritesChatsWA] = useState([]);
  const [favouritesChatsTG, setFavouritesChatsTG] = useState([]);
  const formItems = useRef({ chat: "", startDate: "", endDate: "" });
  const notyf = useMemo(() => new Notyf(), []);
  const nameLocalStorageWA = getStorageNameSelectedChats(profileWa?.profile_id);
  const nameLocalStorageTG = getStorageNameSelectedChats(profileTg?.profile_id);
  const storedChatsWA = localStorage.getItem(nameLocalStorageWA);
  if (storedChatsWA) {
    const parsedWA = JSON.parse(storedChatsWA);
    if (JSON.stringify(favouritesChatsWA) !== storedChatsWA) {
      setFavouritesChatsWA(parsedWA);
    }
  }

  const storedChatsTG = localStorage.getItem(nameLocalStorageTG);
  if (storedChatsTG) {
    const parsedTG = JSON.parse(storedChatsTG);
    if (JSON.stringify(favouritesChatsTG) !== storedChatsTG) {
      setFavouritesChatsTG(parsedTG);
    }
  }
  useEffect(() => {
    if (selectedChat == null) {
      setSelectedTopic(null);
    }
  }, [selectedChat]);

  const handleChatChange = async (selectedOption) => {
    setSelectedChat(selectedOption);
    formItems.current.chat = selectedOption || "";
    if (selectedOption.profile_id === profileTg.profile_id) {
      const response = await getTopicsTG(
        profileTg.profile_id,
        selectedOption.value
      );
      if (response.topics.length) {
        setAllTopicsTG(response.topics);
      } else {
        setAllTopicsTG([]);
      }
    } else {
      setAllTopicsTG([]);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    formItems.current.startDate = date[0] || "";

    if (date[0]) {
      setEndDate((prevEndDate) =>
        prevEndDate && new Date(prevEndDate) < new Date(date[0])
          ? null
          : prevEndDate
      );
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    formItems.current.endDate = date[0] || "";

    if (date[0]) {
      setStartDate((prevStartDate) =>
        prevStartDate && new Date(prevStartDate) > new Date(date[0])
          ? null
          : prevStartDate
      );
    }
  };

  const handleFormSubmit = () => {
    const { chat, startDate, endDate } = formItems.current;
    if (
      !chat ||
      !startDate ||
      !endDate ||
      (allTopicsTG.length ? !selectedTopic : false)
    ) {
      notyf.error("Заполните все поля перед отправкой");
      return;
    }

    if (onSubmit) {
      onSubmit(formItems.current);
      formItems.current = {
        chat: "",
        startDate: "",
        endDate: "",
        topic_id: "",
      };
      setSelectedChat(null);
      setSelectedTopic(null);
      setAllTopicsTG([]);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleMenuOpen = async () => {
    // if (!allChatsTG.length || !allChatsTG.length) await loadChats?.();
  };

  const filtersAction = {
    text: "Загрузить отчет",
    type: !selectedChat || !startDate || !endDate ? "secondary" : "primary",
    selector: "button",
    disabled:
      !profileTg?.authorized && !profileWa?.authorized && !currentUser.access,
    action: handleFormSubmit,
  };

  return (
    <div className={styles.filters}>
      <div className={styles.filtersFields}>
        <div className={styles.filtersField}>
          <Select
            {...selectTheming(false)}
            components={{ DropdownIndicator, ClearIndicator }}
            value={selectedChat}
            onChange={handleChatChange}
            onMenuOpen={handleMenuOpen}
            options={[
              ...allChatsWA
                .filter((chat) => favouritesChatsWA.includes(chat.id))
                .map((c) => ({
                  value: c.id,
                  label: c.name,
                  profile_id: profileWa.profile_id,
                })),
              ...allChatsTG
                .filter((chat) => favouritesChatsTG.includes(chat.id))
                .map((c) => ({
                  value: c.id,
                  label: c.name,
                  profile_id: profileTg.profile_id,
                })),
            ]}
            noOptionsMessage={() => "Результатов не найдено"}
            isClearable
            placeholder={isLoadingChats ? "Загрузка чатов..." : "Выберите чат"}
          />{" "}
        </div>
        {allTopicsTG.length ? (
          <div className={styles.filtersField}>
            <Select
              {...selectTheming(false)}
              components={{ DropdownIndicator, ClearIndicator }}
              value={selectedTopic}
              onChange={(selectedOption) => {
                setSelectedTopic(selectedOption);
                formItems.current.topic_id = selectedOption || "";
              }}
              options={[
                {
                  value: undefined,
                  label: "Выбрать все",
                  profile_id: profileTg.profile_id,
                },
                ...allTopicsTG.map((c) => ({
                  value: c.id,
                  label: c.title,
                  profile_id: profileTg.profile_id,
                })),
              ]}
              noOptionsMessage={() => "Результатов не найдено"}
              isClearable
              placeholder={
                isLoadingChats ? "Загрузка тредов..." : "Выберите тред"
              }
            />
          </div>
        ) : null}

        <div className={styles.filtersField}>
          <Flatpickr
            value={startDate}
            onChange={handleStartDateChange}
            options={{
              dateFormat: "d.m.Y",
              locale: Russian,
              maxDate: endDate ? new Date(endDate) : null,
            }}
            placeholder="Выберите начало периода"
            className={inputStyles.input}
          />
        </div>
        <div className={styles.filtersField}>
          <Flatpickr
            value={endDate}
            onChange={handleEndDateChange}
            options={{
              dateFormat: "d.m.Y",
              locale: Russian,
              minDate: startDate ? new Date(startDate) : null,
            }}
            placeholder="Выберите конец периода"
            className={inputStyles.input}
          />
        </div>
      </div>
      <div className={styles.filtersAction}>
        <Button {...filtersAction} />
      </div>
    </div>
  );
};

export default Filters;
