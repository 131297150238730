import React, { useRef, useState } from "react";
import { AppSampleObjects } from "../../_AppSampleObjects";
import styles from "./Dashboard.module.css";
import Layout from "../../components/Layout/Layout";
import Report from "../../components/Report/Report";
import ReportPlaceholder from "../../components/ReportPlaceholder/ReportPlaceholder";
import Filters from "../../components/Filters/Filters";
import SupportBox from "../../components/SupportBox/SupportBox";
import HistoryReports from "../../components/HistoryReports/HistoryReports";
import { currentDateTime } from "../../utils/utils";
import StatusProfile from "../../components/StatusProfile/StatusProfile";
import { Divider } from "@mui/material";

const Dashboard = ({
  onLogout,
  currentUser,
  profileWa,
  profileTg,
  handleConnect,
  handleDisConnect,
  handleSelectChats,
  allChatsTG,
  allChatsWA,
  loadChats,
  handleFormSumbitFilter,
  allReports,
  getReport,
  currentReport,
  onCloseReport,
  onLoadMessages,
  delByReport,
  handleClickInLabelForFilter,
  isLoadingChats,
  getTopicsTG,
}) => {
  const refDashBoard = useRef(null);
  const getOrderReport = (id) => {
    getReport(id);
  };

  const handleFormSubmit = (formData) => {
    handleFormSumbitFilter(formData);
  };

  return (
    <Layout onLogout={onLogout} currentUser={currentUser}>
      <div className={styles.board}>
        <StatusProfile
          handleConnect={handleConnect}
          handleDisConnect={handleDisConnect}
          profileTg={profileTg}
          profileWa={profileWa}
          handleSelectChats={handleSelectChats}
          isLoadingChats={isLoadingChats}
        />
        <Filters
          profileTg={profileTg}
          profileWa={profileWa}
          onSubmit={handleFormSubmit}
          allChatsWA={allChatsWA}
          allChatsTG={allChatsTG}
          loadChats={loadChats}
          currentUser={currentUser}
          isLoadingChats={isLoadingChats}
          getTopicsTG={getTopicsTG}
        />
        <div ref={refDashBoard}>
          <Divider />
        </div>
        {currentReport ? (
          <Report
            responseReport={currentReport}
            onClose={onCloseReport}
            onLoadMessages={onLoadMessages}
            delByReport={delByReport}
            onClickLabel={handleClickInLabelForFilter}
          />
        ) : (
          <ReportPlaceholder
            {...{
              title: "Нет активного отчета",
              subtitle:
                "Для генерации отчета выберите: чат и период времени, либо нажмите на один из предыдущих отчетов",
            }}
          />
        )}
        <div className={styles.boardAdditional}>
          <h2 className={styles.boardAdditionalTitle}>Предыдущие отчеты</h2>
          <div className={styles.boardAdditionalItems}>
            {allReports.length ? (
              <HistoryReports
                reports={allReports}
                getReport={(id) => {
                  getOrderReport(id);
                  refDashBoard.current?.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                  });
                }}
              />
            ) : (
              <ReportPlaceholder
                {...{ title: "Предыдущих отчетов не обнаружено" }}
              />
            )}
            <SupportBox />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
