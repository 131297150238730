import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import mainApi from "../../utils/MainApi";

const UserTable = ({ users, onUpdateUser, onDeleteUser }) => {
  const [editingId, setEditingId] = useState(null);
  const [editedUser, setEditedUser] = useState({});

  const handleEditClick = (user) => {
    console.log("handleEditClick", user);
    setEditingId(user._id);
    setEditedUser(user);
  };

  const handleSaveClick = () => {
    onUpdateUser(editingId, editedUser);
    setEditingId(null);
  };

  const handleChange = (e) => {
    setEditedUser({
      ...editedUser,
      [e.target.name]: e.target.value,
    });
  };

  const toggleAccess = async (user) => {
    const updatedUser = { ...user, access: !user.access };
    // const res = await mainApi.toggleAccess({
    //   id: user._id,
    //   access: !user.access,
    // });
    onUpdateUser(user._id, updatedUser);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>Имя</TableCell>
            <TableCell>Эл. почта</TableCell>
            <TableCell>Пароль</TableCell>
            <TableCell>ID профиля TG</TableCell>
            <TableCell>Провайдер TG</TableCell>
            <TableCell>ID профиля WA</TableCell>
            <TableCell>Доступ</TableCell>
            <TableCell>Действия</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, i) => (
            <TableRow key={user._id} selected={user.is_admin}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>
                {editingId === user._id ? (
                  <TextField
                    name="name"
                    value={editedUser.name}
                    onChange={handleChange}
                  />
                ) : (
                  `${user.name} ${user.is_admin ? "(admin)" : ""}`
                )}
              </TableCell>
              <TableCell>
                {editingId === user._id ? (
                  <TextField
                    name="email"
                    value={editedUser.email}
                    onChange={handleChange}
                  />
                ) : (
                  user.email
                )}
              </TableCell>
              <TableCell>
                {editingId === user._id ? (
                  <TextField
                    name="password"
                    type="text"
                    value={editedUser.password}
                    onChange={handleChange}
                  />
                ) : (
                  "******"
                )}
              </TableCell>
              <TableCell>
                {editingId === user._id ? (
                  <TextField
                    name="profile_tg"
                    value={editedUser.profile_tg}
                    onChange={handleChange}
                  />
                ) : (
                  <a
                    href={`https://wappi.pro/dashboard/${user.profile_tg}`}
                    target="_blank"
                  >
                    {user.profile_tg}
                  </a>
                )}
              </TableCell>{" "}
              <TableCell>
                {editingId === user._id ? (
                  <Select
                    value={editedUser.provider_tg ?? "wappi"}
                    onChange={handleChange}
                    name="provider_tg"
                  >
                    {" "}
                    <MenuItem key={1} value={"telethon"}>
                      telethon
                    </MenuItem>
                    <MenuItem key={2} value={"wappi"}>
                      wappi
                    </MenuItem>
                  </Select>
                ) : (
                  user.provider_tg ?? "wappi"
                )}
              </TableCell>
              <TableCell>
                {editingId === user._id ? (
                  <TextField
                    name="profile_wa"
                    value={editedUser.profile_wa}
                    onChange={handleChange}
                  />
                ) : (
                  <a
                    href={`https://wappi.pro/dashboard/${user.profile_wa}`}
                    target="_blank"
                  >
                    {user.profile_wa}
                  </a>
                )}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color={user.access ? "secondary" : "primary"}
                  onClick={() => toggleAccess(user)}
                >
                  {user.access ? "Закрыть" : "Открыть"}
                </Button>
              </TableCell>
              <TableCell>
                {editingId === user._id ? (
                  <>
                    <IconButton onClick={handleSaveClick}>
                      <SaveIcon />
                    </IconButton>
                    <IconButton onClick={() => setEditingId(null)}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton onClick={() => handleEditClick(user)}>
                      <EditIcon />
                    </IconButton>
                    {!user.is_admin && (
                      <IconButton onClick={() => onDeleteUser(user)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
